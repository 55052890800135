const initialState = {
  isLoadingAdmin: false,
  adminDashboard: false,
  isAdmin: false,
  error: false,
  onsuccessAdmin: false,
  walletsWhitelist: false,
  usersList: false,
  stakesADMIN: false,
};

export const adminPanelReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "ADMIN_LOADING":
      return {
        ...state,
        isLoadingAdmin: true,
        error: false,
        onsuccessAdmin: false,
        usersList: false,
        stakesADMIN: false,

      };
    case "ADMIN_DATA":
      return {
        ...state,
        isLoadingAdmin: false,
        isAdmin: payload,
        error: false,
        onsuccessAdmin: false,
      };
    //DASHBOARD
    case "ADMIN_DASHBOARD":
      return {
        ...state,
        isLoadingAdmin: false,
        adminDashboard: payload.tempObj,
        error: false,
        onsuccessAdmin: payload.message,
      };
    case "ADMIN_DELETE_PHASE":
      return {
        ...state,
        isLoadingAdmin: false,
        error: false,
        onsuccessAdmin: payload.message,
      };
    //WHITELIST
    case "ADMIN_ADD_WHITELIST":
      return {
        ...state,
        isLoadingAdmin: false,
        onsuccessAdmin: payload.message,
        error: false,
      };
    case "ADMIN_GET_WHITELIST":
      return {
        ...state,
        isLoadingAdmin: false,
        error: false,
        onsuccessAdmin: payload.message,
        walletsWhitelist: payload.wallets,
      };
    case "ADMIN_DELETE_WHITELIST":
      return {
        ...state,
        isLoadingAdmin: false,
        error: false,
        onsuccessAdmin: payload.message,
        walletsWhitelist: false,
      };
    //WITHDRAW
    case "ADMIN_WITHDRAW":
      return {
        ...state,
        isLoadingAdmin: false,
        error: false,
        onsuccessAdmin: payload.message,
        adminDashboard: false,
        walletsWhitelist: false,
      };
    //CONFIG
    case "ADMIN_NEW_DISPATCHER":
      return {
        ...state,
        isLoadingAdmin: false,
        error: false,
        onsuccessAdmin: payload.message,
        adminDashboard: false,
        walletsWhitelist: false,
      };
    case "ADMIN_NEW_DATE_END":
      return {
        ...state,
        isLoadingAdmin: false,
        error: false,
        onsuccessAdmin: payload.message,
        adminDashboard: false,
        walletsWhitelist: false,
      };
    case "ADMIN_USER_LIST":
      return {
        ...state,
        isLoadingAdmin: false,
        error: false,
        onsuccessAdmin: payload.message,
        adminDashboard: false,
        walletsWhitelist: false,
        usersList: payload.tempArray,
      };
    case "ADMIN_ERROR":
      return {
        ...state,
        isLoadingAdmin: false,
        error: payload,
        onsuccessAdmin: false,
      };
    case "ADMIN_FETCH_STAKES":
      return {
        ...state,
        onsuccessAdmin: payload.message,
        error: false,
        isLoadingAdmin: false,
        stakesADMIN: payload.data,

      };
    case "ADMIN_CHECK":
      return {
        ...state,
        onsuccessAdmin: payload.message,
        error: false,
      };
    case "ADMIN_VESTING":
      return {
        ...state,
        isLoadingAdmin: false,
        onsuccessAdmin: payload.message,
        error: false,
      };
    case "ADMIN_RESET_MESSAGE":
      return {
        ...state,
        onsuccessAdmin: false,
      };
    case "DISCONNECT_WALLET":
      return {
        ...initialState,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
