import { combineReducers } from "redux";
import { walletReducer } from "./Wallet";
import { adminPanelReducer } from "./AdminPanel";
import { stakeReducer } from "./Stake";
import { securityReducer } from "./security";
import { phaseReducer } from "./Phase";
import { txhashReducer } from "./Tx";
import { purchaseReducer } from "./Purchase";

export const rootReducer = combineReducers({
  wallet: walletReducer,
  adminPanel: adminPanelReducer,
  stake: stakeReducer,
  security: securityReducer,
  phase: phaseReducer,
  txhash: txhashReducer,
  purchase: purchaseReducer,

});
