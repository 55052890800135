import React from "react";

const P3 = ({ children, extraclassname, ...props }) => {
  return (
    <p {...props} className={`p3 ${extraclassname ? extraclassname : ""}`}>
      {children}
    </p>
  );
};

export default P3;
