import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// import Scrollbar from "smooth-scrollbar";

// const overscrollOptions = {
//   enable: true,
//   effect: "bounce",
//   damping: 0.15,
//   maxOverscroll: 150,
//   glowColor: "#fff",
// };

// const overscrollOptions = {
//   enable: true,
//   effect: "glow",
//   damping: 0.1,
//   maxOverscroll: 200,
// };

// const options = {
//   damping: 0.07,
//   plugins: {
//     overscroll: { ...overscrollOptions },
//   },
// };

const ScrollTop = ({ children }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  // useEffect(() => {
  //   Scrollbar.init(document.body, options);

  //   return () => {
  //     if (Scrollbar) Scrollbar.destroy(document.body);
  //   };
  // }, []);
  return children;
};

export default ScrollTop;
