import React from "react";
import Modal from "..";
import Label1 from "../../Form/CustomTags/Label1";
import P2 from "../../Form/CustomTags/P2";
import "./style.css";
import { useSelector, useDispatch } from "react-redux";
import { shortenAddress } from "../../../utils";

const TxModal = ({ visible }) => {
  const dispatch = useDispatch();
  const link =
    process.env.REACT_APP_NETWORK_ENV === "mainnet"
      ? process.env.REACT_APP_MAINNET_URL
      : process.env.REACT_APP_TESTNET_URL;
  const { txHash } = useSelector(({ txhash }) => txhash);

  const closeHandler = () => {
    dispatch({
      type: "TXHASH_RESET",
    });
    window.location.reload();
  };

  return (
    <Modal
      visible={visible}
      onClose={() => closeHandler()}
      title="¡Transacción realizada con éxito!"
    >
      <div className="txmodal--container aic anim">
        <div className="txmodal-status aic anim wave">
          <span className="anim" />
        </div>
        <div className="txmodal-info aic">
          <P2>Transaction receipt</P2>
          <Label1>
            View on BscScan:{" "}
            <a
              href={`${link}/tx/${txHash}`}
              target={"_blank"}
              rel="noopener noreferrer"
              style={{ textDecoration: "underline" }}
            >
              {shortenAddress(txHash)}
            </a>
          </Label1>
        </div>
      </div>
    </Modal>
  );
};

export default TxModal;
