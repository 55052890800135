
const initialState = {
  isLoadingStake: false,
  errorStake: false,
  successStake: false,
  stakeData: false,
  stakeDataUser: false,
  isLoadingSinglestake: false,
};

export const stakeReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "STAKE_LOADING":
      return {
        ...state,
        isLoadingStake: true,
        errorStake: false,
        isLoadingSinglestake: false,
        successStake: false,
        stakeData: false,
        stakeDataUser: false,
      };
    case "STAKE_CLEAN":
      return {
        ...state,
        errorStake: false,
        successStake: false,
        isLoadingSinglestake: false

      };
    case "STAKE_DATA":
      return {
        ...state,
        isLoadingStake: false,
        errorStake: false,
        stakeData: payload.data,
        successStake: payload.message,
        isLoadingSinglestake: false,
      };
    case "STAKE_DATA_USER":
      return {
        ...state,
        isLoadingStake: false,
        errorStake: false,
        stakeDataUser: payload.data,
        successStake: payload.message,
        isLoadingSinglestake: false,
      };
    case "STAKE_SINGLE_LOADING":
      return {
        ...state,
        isLoadingSinglestake: true
      };
    case "STAKE_CHECK":
      return {
        ...state,
        successStake: payload.message,
      };
    case "STAKE_DEPOSIT":
      return {
        ...state,
        isLoadingStake: false,
        errorStake: false,
        isLoadingSinglestake: false,
        successStake: payload.message,

      };
    case "STAKE_ERROR":
      return {
        ...state,
        isLoadingStake: false,
        isLoadingSinglestake: false,
        errorStake: payload,
        successStake: false,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
