/* eslint-disable */
import React, { createContext, useState, useEffect } from "react";
export const ThemeContext = createContext();
export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setDarkMode] = useState(false);
  const toggleTheme = () => {
    setDarkMode((prev) => !prev);
  };
  useEffect(() => {
    if (localStorage.getItem("darkMode") === "true") {
      setDarkMode(true);
    }
    checkBodyClass();
  }, []);
  useEffect(() => {
    localStorage.setItem("darkMode", isDarkMode);
    checkBodyClass();
  }, [isDarkMode]);
  const checkBodyClass = () => {
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
      document.body.classList.add("anim");
      document.body.classList.remove("light-mode");
    } else {
      document.body.classList.add("light-mode");
      document.body.classList.add("anim");
      document.body.classList.remove("dark-mode");
    }
  };

  return (
    <ThemeContext.Provider
      value={React.useMemo(
        () => ({ isDarkMode, toggleTheme }),
        [isDarkMode, toggleTheme]
      )}
    >
      {children}
    </ThemeContext.Provider>
  );
};
