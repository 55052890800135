const initialState = {
  isLoadingPhase: false,
  dataPhase: false,
  errorPhase: false,
  onsuccessPhase: false,
};

export const phaseReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "PHASE_LOADING":
      return {
        ...state,
        isLoadingPhase: true,
        errorPhase: false,
        onsuccessPhase: false,
        dataPhase: false,
      };
    case "PHASE_DATA":
      return {
        ...state,
        isLoadingPhase: false,
        dataPhase: payload.tempObj,
        errorPhase: false,
      };
    case "PHASE_ERROR":
      return {
        ...state,
        isLoadingPhase: false,
        errorPhase: payload,
        onsuccessPhase: false,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
