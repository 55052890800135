/* eslint-disable */
import React, { createContext, useEffect, useState } from "react";
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import { useDispatch } from "react-redux";
import WalletConnectProvider from "@walletconnect/web3-provider";
import {
  setWalletData,
  setWalletError,
  setWalletDisconnect,
} from "../store/Reducers/Wallet/actions";

//
export const WalletWeb3Context = createContext();
export const WalletWeb3Provider = ({ children }) => {
  const dispatch = useDispatch();
  const [web3Modal, setWeb3Modal] = useState(null);
  const [provider, setprovider] = useState(null);
  const [library, setlibrary] = useState(null);
  const [walletAddress, setwalletAddress] = useState(false);
  useEffect(() => {
    const newWeb3Modal = new Web3Modal({
      cacheProvider: true,
      theme: "dark",
      providerOptions: {
        injected: {
          package: WalletConnectProvider,
          options: {
            rpc: {
              56: "https://bsc-dataseed1.binance.org",
              97: "https://data-seed-prebsc-2-s3.binance.org:8545",
            },
          },
        },
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            rpc: {
              56: "https://bsc-dataseed1.binance.org",
              97: "https://data-seed-prebsc-2-s3.binance.org:8545",
            },
          },
        },
      },
    });
    setWeb3Modal(newWeb3Modal);
  }, []);
  ///connect wallet
  async function connectWallet() {
    try {
      const providerWeb3 = await web3Modal.connect();
      setprovider(providerWeb3);
      //
      const libraryWeb3 = new ethers.providers.Web3Provider(providerWeb3);
      setlibrary(libraryWeb3);
      //
      const accountsWeb3 = await libraryWeb3.listAccounts();
      setwalletAddress(accountsWeb3[0]);
      const network = await libraryWeb3.getNetwork();
      setWalletData(dispatch, network, libraryWeb3, accountsWeb3[0]);
    } catch (error) {
      console.log(error);
      setWalletError(dispatch, error.message);
      localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
    }
  }
  //disconnect wallet
  async function disconnectWallet() {
    web3Modal.clearCachedProvider();
    setwalletAddress(false);
    setWalletDisconnect(dispatch);
  }

  useEffect(() => {
    if (web3Modal && web3Modal?.cachedProvider) {
      console.log(" web3 connected");
      connectWallet();
    }
  }, [web3Modal]);
  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts) => {
        if (accounts) {
          if (accounts.length === 0) {
            console.log("handleAccountsChanged 0");
            disconnectWallet();
          } else {
            connectWallet();
          }
        }
      };
      const handleChainChanged = (_hexChainId) => {
        console.log("handleChainChanged", _hexChainId);
        disconnectWallet();
      };
      const handleDisconnect = () => {
        console.log("handleDisconnect");
        disconnectWallet();
      };
      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);
      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider]);

  return (
    <WalletWeb3Context.Provider
      value={{
        connectWallet,
        disconnectWallet,
        provider,
        library,
        walletAddress,
      }}
    >
      {children}
    </WalletWeb3Context.Provider>
  );
};
