import React from "react";

const Label1 = ({ children, extraclassname, ...props }) => {
  return (
    <label
      {...props}
      className={`label1 anim ${extraclassname ? extraclassname : ""} `}
    >
      {children}
    </label>
  );
};

export default Label1;
