import React from "react";
import "./style.css";
const Spinner = ({ full, children, ...props }) => {
  return (
    <div
      {...props}
      className={`spinner--container aic anim ${
        full ? "active-fullscreen" : ""
      }`}
    >
      <div className="spinner-icon" />
      {children}
    </div>
  );
};

export default Spinner;
