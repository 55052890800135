import React, { useEffect, useCallback, memo } from "react";
import "./style.css";
import Item from "..//Item";
import P3 from "..//Form/CustomTags/P3";
import closeIcon from "../..//assets//images//icons//close.svg";
const Modal = ({ visible, onClose, children, title, description, navbar }) => {
  const escFunction = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  if (navbar) {
    if (visible)
    return (
      <>
        <div
          id="modal"
          className="modal--backdrop "
          onClick={() => onClose()}
        />
        <div className="modal--container--navbar aic anim">
          <Item
            leftcontent={
              <>
                <h4 style={{ fontStyle: "normal" }}>{title}</h4>
                <P3>{description}</P3>
              </>
            }
            iconRight={closeIcon}
            iconRightClick={() => onClose()}
          />
          <div className="modal-body aic anim">{children}</div>
        </div>
      </>
    );
  } 

  if (visible)
    return (
      <>
        <div
          id="modal"
          className="modal--backdrop "
          onClick={() => onClose()}
        />
        <div className="modal--container aic anim">
          <Item
            leftcontent={
              <>
                <h4 style={{ fontStyle: "normal" }}>{title}</h4>
                <P3>{description}</P3>
              </>
            }
            iconRight={closeIcon}
            iconRightClick={() => onClose()}
          />
          <div className="modal-body aic anim">{children}</div>
        </div>
      </>
    );
};

export default memo(Modal);
