import {
  MainNet, TestNet,
  contractTokenAddress,
  tokenABI,
  saleTokenAddress,
  saleABI,
  busdABI,
  usdtABI,
  busdTokenAddress,
  usdtTokenAddress,
  stakeTokenAddress,
  stakeABI,
} from "../../../utils/constants";
import { setAdminData } from "..//AdminPanel/actions";
import { Contract, ethers } from "ethers";
export const setWalletData = async (dispatch, network, libraryWeb3, walletAddress) => {
  try {
    dispatch({
      type: "WALLET_LOADING",
    });
    let isWrongNetwork = false, extra = false
    let chainId =
      process.env.REACT_APP_NETWORK_ENV === "mainnet"
        ? process.env.REACT_APP_MAINNET_CHAINID
        : process.env.REACT_APP_TESTNET_CHAINID;

    if (network.chainId !== parseInt(chainId)) {
      isWrongNetwork = true;
    } else {
      let signer = libraryWeb3.getSigner();
      let tokenContract = new Contract(contractTokenAddress, tokenABI, signer);
      let busdContract = new Contract(busdTokenAddress, busdABI, signer);
      let usdtContract = new Contract(usdtTokenAddress, usdtABI, signer);

      let busdBalanceOf = await busdContract.balanceOf(walletAddress);
      let usdtBalanceOf = await usdtContract.balanceOf(walletAddress);
      let balanceOf = await tokenContract.balanceOf(walletAddress);
      busdBalanceOf = ethers.utils.formatEther(busdBalanceOf)
      usdtBalanceOf = ethers.utils.formatEther(usdtBalanceOf)
      balanceOf = ethers.utils.formatEther(balanceOf)
      extra = { balanceOf, busdBalanceOf, usdtBalanceOf }
      setAdminData(dispatch, libraryWeb3, walletAddress);
    }

    dispatch({
      type: "WALLET_DATA",
      payload: {
        isWrongNetwork,
        extra
      },
    });
  } catch (error) {
    dispatch({
      type: "WALLET_ERROR",
      payload: error,
    });
  }
};
export const setWalletNetwork = async (dispatch, library) => {
  try {
    dispatch({
      type: "WALLET_LOADING",
    });
    await library.provider.request({
      method: "wallet_addEthereumChain",
      params: [
        process.env.REACT_APP_NETWORK_ENV === "mainnet" ? MainNet : TestNet,
      ],
    });
  } catch (error) {
    dispatch({
      type: "WALLET_ERROR",
      payload: error.message,
    });
  }
};
export const setWalletDisconnect = async (dispatch) => {
  dispatch({
    type: "WALLET_DISCONNECT",
  });
};
export const setWalletError = async (dispatch, error) => {
  dispatch({
    type: "WALLET_ERROR",
    payload: error,
  });
};
export const purchase_token = async (
  dispatch,
  library,
  dataform,
  walletAddress,
  isBUSD
) => {
  try {

    dispatch({
      type: "WALLET_LOADING",
    });
    dispatch({
      type: "WALLET_MESSAGE",
      payload: { message: "Verificando transacción, por favor espera...⌚" },
    });
    let transactionHash = ``;
    let message = ``;
    if (!isBUSD) {
      dispatch({
        type: "WALLET_MESSAGE",
        payload: { message: "Recuerda usar el valor predetermiando o máximo en el approve" },
      });
      // let usdtETHER = ethers.utils.parseUnits(dataform.busd, "ether");
      let xrenETHER = ethers.utils.parseUnits(dataform.xren, "ether");
      let signer = library.getSigner();
      const usdtContract = new Contract(usdtTokenAddress, usdtABI, signer);
      let usdtBalanceOf = await usdtContract.balanceOf(walletAddress);
      const approve = await usdtContract.approve(
        saleTokenAddress,
        usdtBalanceOf.toString()
      )
      const awaitTransactionapprove = await approve.wait()
      console.log(awaitTransactionapprove);
      const saleContract = new Contract(saleTokenAddress, saleABI, signer);
      const buyTokenWithToken = await saleContract.buyTokenWithToken(xrenETHER);
      const approvalbuyTokenWithToken = await buyTokenWithToken.wait();
      console.log(approvalbuyTokenWithToken)
      transactionHash = approvalbuyTokenWithToken.transactionHash;
      message = `Se compro : ${dataform.xren} XREN 🎉`;
    } else {
      // let busdETHER = ethers.utils.parseUnits(dataform.busd, "ether");
      dispatch({
        type: "WALLET_MESSAGE",
        payload: { message: "Recuerda usar el valor predetermiando o máximo en el approve" },
      });
      let xrenETHER = ethers.utils.parseUnits(dataform.xren, "ether");
      let signer = library.getSigner();
      const busdContract = new Contract(busdTokenAddress, busdABI, signer);
      let busdBalanceOf = await busdContract.balanceOf(walletAddress);
      const approve = await busdContract.approve(
        saleTokenAddress,
        busdBalanceOf.toString()
      )
      const awaitTransactionapprove = await approve.wait()
      console.log(awaitTransactionapprove);
      const saleContract = new Contract(saleTokenAddress, saleABI, signer);
      const buyTokenWithBUSD = await saleContract.buyTokenWithBUSD(xrenETHER, {
        from: walletAddress,
      });
      const approvalbuyTokenWithBUSD = await buyTokenWithBUSD.wait();
      console.log(approvalbuyTokenWithBUSD)
      transactionHash = approvalbuyTokenWithBUSD.transactionHash;
      message = `Has comprado : ${dataform.xren} XREN 🎉`;
    }
    dispatch({
      type: "TXHASH_SET",
      payload: transactionHash,
    });
    dispatch({
      type: "WALLET_SUCCESS",
      payload: { message },
    });
    return true
  } catch (error) {
    console.log(error);
    console.log(error.code);
    console.log(error.reason);
    let message = "Error al realizar la compra";
    if (error.code === "ACTION_REJECTED") {
      message = "Usuario Nego la ultima transferencia"
    }
    if (error.code === "UNPREDICTABLE_GAS_LIMIT") {
      if (error.reason === "execution reverted: insufficient allowance") {
        message = "Recuerda usar el valor predetermiando o máximo en el approve"
      }
    }
    dispatch({
      type: "WALLET_ERROR",
      payload: message,
    });
    return false
  }
};

export const releaseClaim = async (dispatch, library, id) => {
  try {
    dispatch({
      type: "WALLET_LOADING",
    });
    dispatch({
      type: "WALLET_MESSAGE",
      payload: { message: "Verificando transacción, por favor espera...⌚" },
    });
    let signer = library.getSigner();
    const saleContract = new Contract(saleTokenAddress, saleABI, signer);
    const release = await saleContract.release(id)
    dispatch({
      type: "WALLET_MESSAGE",
      payload: { message: "Aprobando.. 🔥" },
    });
    const releaseWait = await release.wait();
    let message = "Claim fue realizado con exito 🎉";
    let transactionHash = releaseWait.transactionHash;
    dispatch({
      type: "TXHASH_SET",
      payload: transactionHash,
    });
    dispatch({
      type: "WALLET_SUCCESS",
      payload: { message },
    });
    return true
  } catch (error) {
    console.log(error);
    console.log(error.code);
    console.log(error.reason);
    let message = "Error al realizar la compra";
    if (error.code === "ACTION_REJECTED") {
      message = "Usuario Nego la ultima transferencia"
    }

    if (error.reason === "execution reverted: Already claim tokens") {
      message = "Ya has reclamado tus tokens"
    }
    dispatch({
      type: "WALLET_ERROR",
      payload: message,
    });
    return false

  }
};
export const rewardsStake = async (dispatch, library, id) => {
  try {
    dispatch({
      type: "WALLET_LOADING",
    });
    dispatch({
      type: "WALLET_MESSAGE",
      payload: { message: "Verificando transacción, por favor espera...⌚" },
    });

    let signer = library.getSigner();
    let stakeContract = new Contract(stakeTokenAddress, stakeABI, signer);
    const getRewards = await stakeContract.getRewards(id);
    console.log(getRewards)
    const waitgetRewards = await getRewards.wait();
    console.log(waitgetRewards)
    let transactionHash = waitgetRewards.transactionHash;
    let message = "Stake fue realizado con exito 🎉";
    dispatch({
      type: "TXHASH_SET",
      payload: transactionHash,
    });
    dispatch({
      type: "WALLET_SUCCESS",
      payload: { message },
    });
    return true
  } catch (error) {
    console.log(error);
    console.log(error.code);
    console.log(error.reason);
    let message = "Error al realizar la compra";
    if (error.code === "ACTION_REJECTED") {
      message = "Usuario Nego la ultima transferencia"
    }
    if (error.code === "UNPREDICTABLE_GAS_LIMIT") {
      // message = "No tienes suficiente BUSD para realizar la transacción"
    }
    if (error.reason === "execution reverted: Already claim tokens") {
      message = "Ya has reclamado tus tokens"
    }
    if (error.reason === "execution reverted: No time to get rewards yet") {
      message = "Todavia no es posible reclamar tus tokens"
    }
    dispatch({
      type: "WALLET_ERROR",
      payload: message,
    });
    return false
  }
};
