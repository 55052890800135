import React, { useContext } from "react";
import Modal from "../";
import Button from "../../Button";
import { useDispatch } from "react-redux";
import { setWalletNetwork } from "../../..//store/Reducers/Wallet/actions";
import { WalletWeb3Context } from "../../../context/WalletWeb3Context";

const WrongNetwork = ({ visible, onClose }) => {
  const dispatch = useDispatch();
  const { library } = useContext(WalletWeb3Context);
  return (
    <Modal
      visible={visible}
      onClose={onClose}
      title="Parece que estas en la red incorrecta"
    >
      <Button secondary onClick={() => setWalletNetwork(dispatch, library)}>
        Cambiar Network
      </Button>
    </Modal>
  );
};

export default WrongNetwork;
