
export const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const getRandomBool = () => {
  return Math.random() >= 0.5;
};
//
export const toHex = (num) => {
  const val = Number(num);
  return "0x" + val.toString(16);
};

export const shortenAddress = (address) => {
  if (!address) return "";
  return `${address.slice(0, 5)}...${address.slice(address.length - 4)}`;
};
export const extraShortenAddress = (address) => {
  if (!address) return "";
  return `${address.slice(0, 2)}..${address.slice(address.length - 2)}`;
};
export const shortenAddressLong = (address) => {
  if (!address) return "";
  return `${address.slice(0, 10)}...${address.slice(address.length - 9)}`;
};
export const localeString = (number) => {
  return parseFloat(number).toLocaleString();
};
export const formatDateToLocalString = (date) => {
  return new Date(date).toLocaleDateString(undefined, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};
export const formatDateToLocalStringShort = (date) => {
  return new Date(date).toLocaleDateString(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};
//Disponible/Reclamado para Dashboard ICO
export const formatDateToLocalStringReadyClaimed = (date,disable) => {
  if (!disable) {
    return "Disponible";
  }

  const datestring = new Date(date).toLocaleDateString(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
  })

  if (datestring === "Invalid Date") {
    return "Reclamado";
  } else {
    return datestring
  }
};
const DIVISIONS = [
  { amount: 60, name: 'seconds' },
  { amount: 60, name: 'minutes' },
  { amount: 24, name: 'hours' },
  { amount: 7, name: 'days' },
  { amount: 4.34524, name: 'weeks' },
  { amount: 12, name: 'months' },
  { amount: Number.POSITIVE_INFINITY, name: 'years' }
]
export const formatTimeAgo = (date) => {
  //https://blog.webdevsimplified.com/2020-07/relative-time-format/
  const formatter = new Intl.RelativeTimeFormat(undefined, {
    numeric: 'auto'
  })
  let duration = (date - new Date()) / 1000

  for (let i = 0; i <= DIVISIONS.length; i++) {
    const division = DIVISIONS[i]
    if (Math.abs(duration) < division.amount) {
      return formatter.format(Math.round(duration), division.name)
    }
    duration /= division.amount
  }
};
