import { setJWT } from "../../../utils/axios";

const initialState = {
  isLoadingSecuirty: false,
  isLogged: false,
  errorSecurity: false,
  successSecurity: false,
  userSecurity: false,
  isVerifyingSecurity: false

};
setJWT(localStorage.getItem("jwt_token"));
export const securityReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SECURITY_LOADING":
      return {
        ...state,
        isLoadingSecuirty: true,
        errorSecurity: false,
        successSecurity: false,

      };
    case "SECURITY_FETCH_AUTHCODE":
      return {
        ...state,
        isLoadingSecuirty: false,
        errorSecurity: false,
        successSecurity: payload.message,
        isVerifyingSecurity: payload.email,
        isLogged: false,
        userSecurity: false,
      };
    case "SECURITY_LOGIN":
      setJWT(payload.token);
      localStorage.setItem("jwt_token", payload.token);
      return {
        ...state,
        isLoadingSecuirty: false,
        isLogged: true,
        errorSecurity: false,
        successSecurity: payload.message,
        userSecurity: payload.user
      };
    case "SECURITY_SIGNUP":
      return {
        ...state,
        isLoadingSecuirty: false,
        isLogged: false,
        successSecurity: payload.message,
        errorSecurity: false,
      };
    case "SECURITY_ERROR":
      return {
        ...state,
        isLoadingSecuirty: false,
        errorSecurity: payload,
        successSecurity: false,
      };
    case "SECURITY_RESET_MESSAGE":
      return {
        ...state,
        successSecurity: false,
        errorSecurity: false,
      };
    case "SECURITY_FAKE_RESET":
      return {
        ...initialState,
      };
    case "DISCONNECT":
      localStorage.removeItem("jwt_token");
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
