import { Contract, ethers } from "ethers";
import {
  saleTokenAddress,
  saleABI,
  contractTokenAddress,
  tokenABI,
  ZERO_ADDRESS,
  stakeTokenAddress,
  stakeABI,
  rpcLink,
} from "../../../utils/constants";
import { paxios } from "../../../utils/axios";
import Web3 from "web3";

import { formatDateToLocalString } from "../../../utils";
//ADMIN STARTUP
export const setAdminData = async (dispatch, library, walletAddress) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    let signer = library.getSigner();
    let saleContract = new Contract(saleTokenAddress, saleABI, signer);
    let hasRole = await saleContract.hasRole(ZERO_ADDRESS, walletAddress);

    dispatch({
      type: "ADMIN_DATA",
      payload: hasRole,
    });
  } catch (error) {
    console.log(error);

    dispatch({
      type: "ADMIN_ERROR",
      payload: "Error Generico desde Metamask",
    });
  }
};
//DASHBOARD
export const setAdminDashbord = async (dispatch, library) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    let tempObj = {};
    let signer = library.getSigner();
    let saleContract = new Contract(saleTokenAddress, saleABI, signer);
    const getcurrentPhase = await saleContract.getcurrentPhase();
    tempObj.endAt = getcurrentPhase.endAt.toString() * 1000;
    tempObj.isPublic = getcurrentPhase.isPublic;
    tempObj.minimunEntry = ethers.utils.formatEther(
      getcurrentPhase.minimunEntry.toString()
    );
    tempObj.over = getcurrentPhase.over;
    tempObj.timelock = parseInt(
      getcurrentPhase.timelock.toString() / 3600 / 24
    );
    tempObj.supply = ethers.utils.formatEther(
      getcurrentPhase.supply.toString()
    );
    tempObj.price = ethers.utils.formatEther(getcurrentPhase.price.toString());
    tempObj.timesToRelease = getcurrentPhase.timesToRelease
      .map((o) => o / 3600)
      .join("Dias ,  ");
    tempObj.percentsToRelease = getcurrentPhase.percentsToRelease.join("% ,  ");

    let message = "Data actualizada";
    dispatch({
      type: "ADMIN_DASHBOARD",
      payload: { tempObj, message },
    });
  } catch (error) {
    console.log(error);
    console.log(error.code);
    console.log(error.reason);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
export const deletePhase = async (dispatch, library) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    let signer = library.getSigner();
    let saleContract = new Contract(saleTokenAddress, saleABI, signer);
    const transaction = await saleContract.cancelPhase();
    const response = await transaction.wait();
    let message = "Fase Eliminada Deber Crear una en ventas";
    dispatch({
      type: "ADMIN_DELETE_PHASE",
      payload: { message, response },
    });
  } catch (error) {
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
//VENTAS
export const setAdminPhase = async (dispatch, dataForm, library) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    const phaseType = dataForm.phaseType;
    const minEntry = ethers.utils
      .parseEther(dataForm.minEntry.toString())
      .toString();
    const price = ethers.utils
      .parseEther(parseFloat(dataForm.price).toString())
      .toString();
    const endAt = new Date(dataForm.endAt).getTime() / 1000;
    const supply = ethers.utils
      .parseEther(dataForm.supply.toString())
      .toString();
    const timelock = dataForm.timelock * 24 * 60 * 60;
    const timesToRelease = dataForm.timesToRelease
      .split(",")
      .sort()
      .map((x) => {
        return x * 24 * 60 * 60;
      });
    const percentsToRelease = dataForm.percentsToRelease
      .split(",")
      .map((percent) => parseFloat(percent));
    //------------------------
    let signer = library.getSigner();
    let tokenContract = new Contract(contractTokenAddress, tokenABI, signer);
    const transaction = await tokenContract.approve(saleTokenAddress, supply);
    const awaitTransaction = await transaction.wait();
    let message = "Verificando, Por favor espera";
    dispatch({
      type: "ADMIN_CHECK",
      payload: { awaitTransaction, message },
    });
    let saleContract = new Contract(saleTokenAddress, saleABI, signer);

    const createPhase = await saleContract.createPhase(
      phaseType,
      minEntry,
      price,
      endAt,
      supply,
      timelock,
      timesToRelease,
      percentsToRelease
    );
    const awaitTransaction2 = await createPhase.wait();

    message = "Fase fue creada Exitosamente";
    dispatch({
      type: "ADMIN_DELETE_PHASE",
      payload: { awaitTransaction2, message },
    });
  } catch (error) {
    console.log(error);
    console.log(error.code);
    console.log(error.reason);
    let message = "Error Generico desde Metamask";
    if (error.reason === "user rejected transaction") {
      message = "Usuario Nego la transferencia";
    }
    dispatch({
      type: "ADMIN_ERROR",
      payload: message,
    });
  }
};
//WHITELIST
export const setWhitelistWallets = async (dispatch, library, isWallets) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    let signer = library.getSigner();
    let saleContract = new Contract(saleTokenAddress, saleABI, signer);
    const addtoWhitelist = await saleContract.addToWhitelist(
      isWallets.split(",")
    );
    const transaction = await addtoWhitelist.wait();
    let message = "Wallets agregadas exitosamente.";
    console.log(transaction);
    dispatch({
      type: "ADMIN_ADD_WHITELIST",
      payload: { transaction, message },
    });
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
export const getWhitelistWallets = async (dispatch, library) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    let signer = library.getSigner();
    let saleContract = new Contract(saleTokenAddress, saleABI, signer);
    console.log(saleContract);
    const wallets = await saleContract.getWhitelist();
    console.log(wallets);
    let message = "Whitelist Actualizada";
    dispatch({
      type: "ADMIN_GET_WHITELIST",
      payload: { message, wallets },
    });
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
export const deleteWhitelist = async (dispatch, library) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    let signer = library.getSigner();
    let saleContract = new Contract(saleTokenAddress, saleABI, signer);
    const transaction = await saleContract.removeWhitelistedAddress();
    const awaittransaction = await transaction.wait();
    let message = "Wallets Eliminadas";
    dispatch({
      type: "ADMIN_DELETE_WHITELIST",
      payload: { message, awaittransaction },
    });
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
//WHITHDRAW
export const setWithdraw = async (dispatch, library, isdataForm) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    const amount = ethers.utils.parseEther(isdataForm.amount.toString());
    let signer = library.getSigner();
    let saleContract = new Contract(saleTokenAddress, saleABI, signer);
    const withdrawBUSD = await saleContract.withdrawBUSD(
      isdataForm.address,
      amount
    );
    console.log(withdrawBUSD);
    const awaittransaction = await withdrawBUSD.wait();
    let message = `Se acaba de retirar:"${isdataForm.amount}" a la wallet ${isdataForm.address} `;

    dispatch({
      type: "ADMIN_WITHDRAW",
      payload: { message, awaittransaction },
    });
  } catch (error) {
    console.log(error);
    console.log(error.code);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
//CONFIG
export const setDispatcher = async (dispatch, library, address) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    let signer = library.getSigner();
    let saleContract = new Contract(saleTokenAddress, saleABI, signer);
    const updateDispatcher = await saleContract.changeDispatcher(address);
    let message = `Nuevo Dispatcher:${address} `;
    dispatch({
      type: "ADMIN_NEW_DISPATCHER",
      payload: { message, updateDispatcher },
    });
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
export const setDateEnd = async (dispatch, library, dateEnd) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    let signer = library.getSigner();
    let saleContract = new Contract(saleTokenAddress, saleABI, signer);
    const transaction = await saleContract.changeEndDate(
      new Date(dateEnd).getTime() / 1000
    );
    const awaittransaction = await transaction.wait();
    let message = `Nuevo Fecha de Finalización : ${dateEnd} `;
    dispatch({
      type: "ADMIN_NEW_DATE_END",
      payload: { message, awaittransaction },
    });
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
////FETCH STAKE
export const fetchStakeDataAdmin = async (dispatch) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    dispatch({
      type: "ADMIN_CHECK",
      payload: {
        message: "Espera unos segundos mientras se cargan los datos 😁",
      },
    });
    let data = [];
    let web3 = new Web3(new Web3.providers.HttpProvider(rpcLink));
    let stakeContract = new web3.eth.Contract(stakeABI, stakeTokenAddress)
      .methods;

    const currentIdStake = await stakeContract.currentIdStake().call();
    let currentIdStakeNumber = parseInt(currentIdStake);
    await Promise.all(
      [...Array(currentIdStakeNumber + 1).keys()].map(async (o) => {
        if (o !== currentIdStakeNumber) {
          let tmepObj = {};
          let idStake = (o + 1).toString();
          const stakes = await stakeContract.stakes(idStake).call();
          tmepObj.id = o + 1;
          tmepObj.APR = stakes.APR;
          tmepObj.data = stakes.data;
          tmepObj.endTime = formatDateToLocalString(stakes.endTime * 1000);
          tmepObj.currentCupo = ethers.utils.formatEther(
            stakes.currentCupo.toString()
          );
          tmepObj.maxCupo = ethers.utils.formatEther(stakes.maxCupo.toString());
          tmepObj.timeLockExit = formatDateToLocalString(
            stakes.timeLockExit * 1000
          );
          tmepObj.timeLockRewards = formatDateToLocalString(
            stakes.timeLockRewards * 1000
          );
          data.push(tmepObj);
        }
      })
    );
    data.sort((a, b) => b.id - a.id);
    let message = "Stakes encontrados exitosamente 🍸.";
    if (currentIdStakeNumber === 0) {
      message = "No se ha creado ningun Stake 🍸.";
      data = false;
    }
    dispatch({
      type: "ADMIN_FETCH_STAKES",
      payload: {
        message,
        data,
      },
    });
  } catch (error) {
    console.log(error);
    console.log(error.code);
    console.log(error.reason);
    dispatch({
      type: "ADMIN_ERROR",
      payload: "Error al cargar stakes 🍸.",
    });
  }
};
////CREATE STAKE
export const createStake = async (dispatch, isdataForm, library) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    let signer = library.getSigner();
    let stakeContract = new Contract(stakeTokenAddress, stakeABI, signer);
    const endtime = new Date(isdataForm.endtime).getTime() / 1000;
    const timelockRecompensa =
      new Date(isdataForm.timelockRecompensa).getTime() / 1000;
    const timelockSalida = new Date(isdataForm.timelockSalida).getTime() / 1000;
    const maxcupo = ethers.utils.parseEther(isdataForm.maxcupo).toString();
    const createStake = await stakeContract.createStake(
      isdataForm.apr,
      maxcupo,
      endtime,
      timelockRecompensa,
      timelockSalida,
      isdataForm.data
    );
    let message = "Verificando, Por favor espera";
    dispatch({
      type: "ADMIN_CHECK",
      payload: { message },
    });
    const awaitTransactioncreateStake = await createStake.wait();
    console.log(awaitTransactioncreateStake);
    message = "Stake fue creado exitosamente. 🎉";
    dispatch({
      type: "ADMIN_DELETE_PHASE",
      payload: { message, awaitTransactioncreateStake },
    });
  } catch (error) {
    console.log(error);
    console.log(error.code);
    console.log(error.reason);
    let message = "Error Generico desde Metamask";
    if (error.code === "ACTION_REJECTED") {
      message = "Usuario Nego la transferencia";
    }
    if (error.reason === "missing argument: passed to contract") {
      message = "Error en los datos ingresados, contacta soporte";
    }
    dispatch({
      type: "ADMIN_ERROR",
      payload: message,
    });
  }
};
////UPDATE STAKE
export const updateStake = async (dispatch, isdataForm, library) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    let signer = library.getSigner();
    let stakeContract = new Contract(stakeTokenAddress, stakeABI, signer);
    const endtime = new Date(isdataForm.endtime).getTime() / 1000;
    const timelockRecompensa =
      new Date(isdataForm.timelockRecompensa).getTime() / 1000;
    const timelockSalida = new Date(isdataForm.timelockSalida).getTime() / 1000;
    const maxcupo = ethers.utils.parseEther(isdataForm.maxcupo).toString();
    const updateStake = await stakeContract.updateStake(
      isdataForm.id,
      isdataForm.apr,
      maxcupo,
      endtime,
      timelockRecompensa,
      timelockSalida,
      isdataForm.data
    );
    let message = "Verificando, Por favor espera";
    dispatch({
      type: "ADMIN_CHECK",
      payload: { message },
    });
    const awaitTransactionupdateStake = await updateStake.wait();
    console.log(awaitTransactionupdateStake);
    message = "Stake fue actualizado exitosamente. 🎉";
    dispatch({
      type: "ADMIN_DELETE_PHASE",
      payload: { message, awaitTransactionupdateStake },
    });
  } catch (error) {
    console.log(error);
    console.log(error.code);
    console.log(error.reason);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
////WITHDRAW TOKENS
export const withdrawTokenStake = async (dispatch, isdataForm, library) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    let signer = library.getSigner();
    let stakeContract = new Contract(stakeTokenAddress, stakeABI, signer);
    const amount = ethers.utils
      .parseEther(isdataForm.amount.toString())
      .toString();
    const withdrawTokens = await stakeContract.withdrawTokens(
      amount,
      isdataForm.address
    );
    let message = "Verificando, Por favor espera";
    dispatch({
      type: "ADMIN_CHECK",
      payload: { message },
    });
    const awaitTransactionwithdrawTokens = await withdrawTokens.wait();
    console.log(awaitTransactionwithdrawTokens);
    message = "Retiro fue  exitoso. 🎉";
    dispatch({
      type: "ADMIN_DELETE_PHASE",
      payload: { message, awaitTransactionwithdrawTokens },
    });
  } catch (error) {
    console.log(error);
    console.log(error.code);
    console.log(error.reason);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
////FUND TOKENS IN STAKE
export const fundTokens = async (dispatch, isdataForm, library) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    let signer = library.getSigner();
    let tokenContract = new Contract(contractTokenAddress, tokenABI, signer);
    const supply = ethers.utils
      .parseEther(isdataForm.monto.toString())
      .toString();
    const transaction = await tokenContract.approve(stakeTokenAddress, supply);
    const awaitTransaction = await transaction.wait();
    let message = "Verificando, Por favor espera";
    dispatch({
      type: "ADMIN_CHECK",
      payload: { awaitTransaction, message },
    });

    let stakeContract = new Contract(stakeTokenAddress, stakeABI, signer);
    const fundThis = await stakeContract.fundThis(supply);
    const awaitTransactionfundThis = await fundThis.wait();
    console.log(awaitTransactionfundThis);
    message = "Deposito de tokens exitoso. 🎉";

    dispatch({
      type: "ADMIN_DELETE_PHASE",
      payload: { message, awaitTransactionfundThis },
    });
  } catch (error) {
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
////FETCH PROJECTOS
export const fetchProyectosDataAdmin = async (dispatch) => {
  try {
    // dispatch({
    //   type: "ADMIN_LOADING",
    // });
    // dispatch({
    //   type: "ADMIN_CHECK",
    //   payload: { message: "Espera unos segundos mientras se cargan los datos 😁" }
    // });
    // dispatch({
    //   type: "ADMIN_FETCH_STAKES",
    //   payload: {
    //     message: "", data: false
    //   },
    // });
  } catch (error) {
    console.log(error);
    console.log(error.code);
    console.log(error.reason);
    dispatch({
      type: "ADMIN_ERROR",
      payload: "Error al cargar usuarios.",
    });
  }
};
////CREATE PROJECTO
export const createProyecto = async (dispatch, isdataForm, library) => {
  // {/* TODO EER: Anadir el proyecto a DB */ }
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    let signer = library.getSigner();
    let stakeContract = new Contract(stakeTokenAddress, stakeABI, signer);
    const endtime = new Date(isdataForm.endtime).getTime() / 1000;
    const timelockRecompensa =
      new Date(isdataForm.timelockRecompensa).getTime() / 1000;
    const timelockSalida = new Date(isdataForm.timelockSalida).getTime() / 1000;
    const maxcupo = ethers.utils.parseEther(isdataForm.maxcupo).toString();
    const createStake = await stakeContract.createStake(
      isdataForm.apr,
      maxcupo,
      endtime,
      timelockRecompensa,
      timelockSalida,
      isdataForm.data
    );
    let message = "Verificando, Por favor espera";
    dispatch({
      type: "ADMIN_CHECK",
      payload: { message },
    });
    const awaitTransactioncreateStake = await createStake.wait();
    console.log(awaitTransactioncreateStake);
    message = "Stake fue creado exitosamente. 🎉";
    dispatch({
      type: "ADMIN_DELETE_PHASE",
      payload: { message, awaitTransactioncreateStake },
    });
  } catch (error) {
    console.log(error);
    console.log(error.code);
    console.log(error.reason);
    let message = "Error Generico desde Metamask";
    if (error.code === "ACTION_REJECTED") {
      message = "Usuario Nego la transferencia";
    }
    if (error.reason === "missing argument: passed to contract") {
      message = "Error en los datos ingresados, contacta soporte";
    }
    dispatch({
      type: "ADMIN_ERROR",
      payload: message,
    });
  }
};
////UPDATE PROJECTO
export const updateProyecto = async (dispatch, isdataForm, library) => {
  // {/* TODO EER: Update el proyecto en DB */ }
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    let signer = library.getSigner();
    let stakeContract = new Contract(stakeTokenAddress, stakeABI, signer);
    const endtime = new Date(isdataForm.endtime).getTime() / 1000;
    const timelockRecompensa =
      new Date(isdataForm.timelockRecompensa).getTime() / 1000;
    const timelockSalida = new Date(isdataForm.timelockSalida).getTime() / 1000;
    const maxcupo = ethers.utils.parseEther(isdataForm.maxcupo).toString();
    const updateStake = await stakeContract.updateStake(
      isdataForm.id,
      isdataForm.apr,
      maxcupo,
      endtime,
      timelockRecompensa,
      timelockSalida,
      isdataForm.data
    );
    let message = "Verificando, Por favor espera";
    dispatch({
      type: "ADMIN_CHECK",
      payload: { message },
    });
    const awaitTransactionupdateStake = await updateStake.wait();
    console.log(awaitTransactionupdateStake);
    message = "Stake fue actualizado exitosamente. 🎉";
    dispatch({
      type: "ADMIN_DELETE_PHASE",
      payload: { message, awaitTransactionupdateStake },
    });
  } catch (error) {
    console.log(error);
    console.log(error.code);
    console.log(error.reason);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
export const fetch_users_list = async (dispatch) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    dispatch({
      type: "ADMIN_CHECK",
      payload: { message: "Cargando usuarios 😁" },
    });
    let message = "Usuarios cargados exitosamente";
    const users = await paxios.get(`/user`);
    let tempArray = Object.keys(users.data.data).map((key) => {
      users.data.data[key].id = key;
      return users.data.data[key];
    });
    if (tempArray.length === 0) {
      message = "No hay usuarios registrados";
      tempArray = false;
    }

    dispatch({
      type: "ADMIN_USER_LIST",
      payload: {
        message,
        tempArray,
      },
    });
  } catch (error) {
    console.log(error);

    dispatch({
      type: "ADMIN_ERROR",
      payload: "Error Generico desde Metamask",
    });
  }
};

////RESET MESSAGE
export const resetMessage = async (dispatch) => {
  dispatch({
    type: "ADMIN_RESET_MESSAGE",
  });
};
