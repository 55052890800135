import React, { Suspense, useState, useEffect } from "react";
import { WalletWeb3Provider } from "./context/WalletWeb3Context";
import { ThemeProvider } from "./context/ThemeContext";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ScrollTop from ".//utils/ScrollTop";
import Spinner from "./components/Spinner";
import { useSelector } from "react-redux";
import WrongNetwork from "./components/Modal/WrongNetwork";
import TxModal from "./components/Modal//TxModal";
// import PrivacyBanner from "./components/PrivacyBanner";
//
const Home = React.lazy(() => import("./pages/Home"));
const Swap = React.lazy(() => import("./pages/Swap"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const Verify = React.lazy(() => import("./pages/Dashboard//Verify"));
const StakeDetail = React.lazy(() => import("./pages/Dashboard/StakeDetail"));
// const Invest = React.lazy(() => import("./pages/Invest"));
const Category = React.lazy(() => import("./pages/Invest/Category"));
const ProductDetail = React.lazy(() => import("./pages/Invest/ProductDetail"));
const Admin = React.lazy(() => import("./pages/Admin"));
const Login = React.lazy(() => import("./pages//Login"));
const Register = React.lazy(() => import("./pages//Register"));
const VerifyLogin = React.lazy(() => import("./pages//VerifyLogin"));

const App = () => {
  const [isModalWrongNetworkOpen, setisModalWrongNetworkOpen] = useState(false);
  const [isModalTxOpen, setisModalTxOpen] = useState(false);
  const { isWrongNetwork } = useSelector(({ wallet }) => wallet);
  const { txHash } = useSelector(({ txhash }) => txhash);

  useEffect(() => {
    if (isWrongNetwork) {
      setisModalWrongNetworkOpen(() => true);
    } else {
      wrongnetworkModalClose();
    }
  }, [isWrongNetwork]);
  useEffect(() => {
    if (txHash) {
      setisModalTxOpen(() => true);
    } else {
      setisModalTxOpen(() => false);
    }
  }, [txHash]);

  const wrongnetworkModalClose = () => {
    setisModalWrongNetworkOpen(() => false);
  };

  return (
    <ThemeProvider>
      <Suspense fallback={<Spinner full>Loading</Spinner>}>
        {/* <PrivacyBanner /> */}
        <WalletWeb3Provider>
          {!!isWrongNetwork && (
            <WrongNetwork
              visible={isModalWrongNetworkOpen}
              onClose={() => wrongnetworkModalClose()}
            />
          )}
          {!!txHash && <TxModal visible={isModalTxOpen} />}
          <BrowserRouter>
            <ScrollTop>
              <Routes>
                <Route path="/" element={<Home />} exact />
                <Route path="/login" element={<Login />} exact />

                <Route path="/register">
                  <Route index element={<Register />} />
                  <Route
                    path="/register/:referallCode"
                    element={<Register />}
                  />
                </Route>

                <Route path="/verifylogin" element={<VerifyLogin />} exact />
                <Route path="/admin" element={<Admin />} exact />
                <Route path="/swap">
                  <Route index element={<Swap />} />
                  <Route path=":option" element={<Swap />} />
                </Route>

                <Route path="/dashboard">
                  <Route index element={<Dashboard />} exact />
                  <Route
                    path="/dashboard/verifyaccount"
                    element={<Verify />}
                    exact
                  />
                  <Route
                    path="/dashboard/stakedetail/"
                    element={<Dashboard />}
                    exact
                  />
                  <Route
                    path="/dashboard/stakedetail/:id"
                    element={<StakeDetail />}
                    exact
                  />
                </Route>
                <Route path="/invest">
                  <Route index element={<Category />} exact />
                  {/* <Route index element={<Invest />} exact /> */}
                  {/* <Route path=":category" element={<Category />} exact /> */}
                  <Route
                    path=":category/:product"
                    element={<ProductDetail />}
                    exact
                  />
                  <Route index element={<Dashboard />} exact />
                  <Route
                    path="/invest/verifyaccount"
                    element={<Verify />}
                    exact
                  />
                </Route>
                <Route path="*" element={<Navigate to={"/"} />} exact />
              </Routes>
            </ScrollTop>
          </BrowserRouter>
        </WalletWeb3Provider>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
