
const initialState = {
  isLoadingPurchase: false,
  errorPurchase: false,
  successPurchase: false,
  purchaseData: false,
};

export const purchaseReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "PURCHASE_LOADING":
      return {
        ...state,
        isLoadingPurchase: true,
        errorPurchase: false,
        successPurchase: false,
      };
    case "PURCHASE_DATA":
      return {
        ...state,
        isLoadingPurchase: false,
        errorPurchase: false,
        purchaseData: payload.data,
        successPurchase: payload.message,
      };
    case "PURCHASE_CHECK":
      return {
        ...state,
        successPurchase: payload.message,
      };
    case "PURCHASE_ERROR":
      return {
        ...state,
        isLoadingPurchase: false,
        errorPurchase: payload,
        successPurchase: false,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
