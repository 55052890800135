import React from "react";
import "./style.css";
import Icon from "../Icon";
const Item = ({
  iconLeft,
  iconRight,
  leftcontent,
  rightcontent,
  iconRightClick,
}) => {
  return (
    <div className="item--container aic anim ">
      {/* ////////// */}
      <div className="item-left aic">
        {iconLeft && <Icon src={iconLeft} className="item-left-icon aic" />}
        <div className="item-left-text aic">{leftcontent}</div>
      </div>
      {/* ////////// */}
      <div className="item-right aic">
        <div className="item-right-text aic">{rightcontent}</div>
        {iconRight && (
          <Icon
            src={iconRight}
            onClick={() => iconRightClick()}
            className="item-left-icon aic hover scale10 anim"
          />
        )}
      </div>
    </div>
  );
};

export default Item;
